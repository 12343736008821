import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addMessage } from "../app/web3Slice";


export function SmLink({ children, to, icon }) {


	return (<div className="block">
		<Link to={to} className='link'>
			<i className={`icon bi bi-${icon}`}></i>
			<span className='text'>{children}</span>
			<i className="arrow bi bi-arrow-right-short"></i>
		</Link>
	</div>)
}


export function SmCopyToClipboard({ text }) {
	const dispatch = useDispatch();

	return (<span className='cc'>
		<CopyToClipboard text={text} onCopy={() => dispatch(addMessage('Address copied to clipboard'))}
		><i className='bi bi-copy' /></CopyToClipboard>
	</span>)
}