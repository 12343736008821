import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadChildList, selectAccountData } from '../../app/web3Slice';
import { BlockAmount, BlockDim, dispAddress, dispNum } from '../../common';
import { SmCopyToClipboard } from '../../components/elems';




export default function ChildList() {

	// ------- selectors -----------
	const childList = useSelector((state) => state.web3.childList);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadChildList());
	}, []);



	return (
		<div className="page">
			<h1>Sponsored</h1>
			<div className="page-main">
				<br />
				<br />
				<div style={{  }}>
					<table className="list">
						<thead>
							<tr>
								<th>Address</th>
							</tr>
						</thead>
						<tbody>

							{childList.map((childAddr, ix) => <tr key={ix}>
								<td>{dispAddress(childAddr)}</td>
							</tr>)}
						</tbody>
					</table>
				</div>
			</div>
			<div className='block'>
				<Link to="/" className='btn btn-secondary'>Home</Link>
			</div>

		</div>
	)
};


