
//export const API_BASE = "http://localhost:25003/api";
export const API_BASE = "https://community.sdbn-token.com:25003/api";


export const sale_address = "0xFF4025C98295cC134D8b4170909dBaA43d0Cd49d";
export const token_address = "0xBe8FD5D31bcC1759EAA635a895A68DE3672AF265";
export const treasury_address = "0x62FB4465340Eef259C10EDc56809B1aedD0D3C8c";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const usdc_address = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";

/*

// ------------------------------- PRODTEST ----------------------------------
export const sale_address = "0x01cC1c9d82d4E6BC91C41a27fefa77DEeE40e9e1";
export const token_address = "0xcA4a529b773ed644f9B886d1EbE25b98161C1650";
export const treasury_address = "0x15745585c739dF633aA9FAF4410efF4e8c086Be3";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const usdc_address = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";


*/

/*
// ------------------------------- TESTNET ----------------------------------
export const CHAIN = {
	chainId: '0x61',
	chainName: 'Binance Smart Chain Testnet',
	nativeCurrency: {
		name: 'tBNB',
		symbol: 'tBNB',
		decimals: 18
	},
	rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com/']
};
*/



// ------------------------------- PRODUCTION ----------------------------------

export const CHAIN = {
	chainId: '0x38',
	chainName: 'Binance Smart Chain',
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18
	},
	rpcUrls: ['https://bsc-dataseed1.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com/']
}

