import { useSelector } from "react-redux";
import { selectAccountData } from "../app/web3Slice";


export function SponsorWarning() {
    const accountData = useSelector(selectAccountData);
    return false;
    if (accountData.parent && accountData.parent != '0x0000000000000000000000000000000000000000')
        return false;
    return <div class='error' style={{'padding':'2em 0 1em 0'}}>
        You have no synchronized sponsor!
    </div>
}