import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _addMessageError, transactionQuery, transactionQueryError, transactionReady } from "../../app/web3Slice";
import axios from "axios";
import { API_BASE } from "../../config/config";

export const web3DepositSlice = createSlice({
	initialState: {
		tran : null,
		last_trans : []
	},
	name: 'web3/deposit',
	reducers: {
		depositStarted: (state, action) => {
			state.tran = action.payload
		},
		depositCanceled: (state, action) => {
			state.tran = null
		},
		depositLastTransLoaded: (state, action) => {
			state.last_trans = action.payload;
		},

	},
	extraReducers: (builder) => { // ------------------------------------------- EXTRA ---------------------------------
		builder.addCase(doDepositLastTrans.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doDepositStart.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doDepositRefresh.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doDepositCancel.rejected, (state, action) => { _addMessageError(state, action); });
	}
});


export const doDepositLastTrans = createAsyncThunk('web3/deposit/last_trans', async (_, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Load last transactions...");
		const resp = await axios.get(API_BASE + `/deposit/last_trans?walletAddress=${walletAddress}&nonce=${nonce}`, { withCredentials: true });
		transactionReady(thunkAPI);
		thunkAPI.dispatch(depositLastTransLoaded(resp.data));
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

export const doDepositStart = createAsyncThunk('web3/deposit/start', async ({ kind, userValue }, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Starting deposit...");
		const resp = await axios.post(API_BASE + '/deposit/start', { walletAddress, nonce, kind, userValue }, { withCredentials: true });
		transactionReady(thunkAPI, resp.data.message);
		thunkAPI.dispatch(depositStarted(resp.data.tran));
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

export const doDepositRefresh = createAsyncThunk('web3/deposit/refresh', async (_, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		//transactionQuery(thunkAPI, "Refreshing deposit...");
		const resp = await axios.post(API_BASE + '/deposit/refresh', { walletAddress, nonce }, { withCredentials: true });
		//transactionReady(thunkAPI, resp.data.message);
		thunkAPI.dispatch(depositStarted(resp.data.tran));
		if (resp.data.last_trans)
			thunkAPI.dispatch(depositLastTransLoaded(resp.data.last_trans));
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

export const doDepositCancel = createAsyncThunk('web3/deposit/cancel', async (_, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Starting deposit...");
		const resp = await axios.post(API_BASE + '/deposit/cancel', { walletAddress, nonce }, { withCredentials: true });
		transactionReady(thunkAPI, resp.data.message);
		thunkAPI.dispatch(depositCanceled(resp.data));
		thunkAPI.dispatch(depositLastTransLoaded(resp.data.last_trans));
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});


export const { depositStarted, depositCanceled, depositLastTransLoaded } = web3DepositSlice.actions;
export default web3DepositSlice.reducer;


