import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, dispNum } from '../../common';
import { PACKAGES, doApproveUSDT, doBuyUSDT } from './buySlice';
import { SponsorWarning } from '../../components/SponsorWarning';




export default function BuyUSDT() {

	const accountData = useSelector(selectAccountData);

	const [approveAmount, setApproveAmount] = useState(0);

	const dispatch = useDispatch();

	const buy = (amount) => {
		dispatch(doBuyUSDT(amount))
	}

	return (
		<div className="page">
			<h1>Buy with USDT</h1>
			<div className="page-main">
				<SponsorWarning />
				<div className="block block-value">
					<span className="title">USDT balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDT} /><span className="dim">USDT</span>
					</span>
				</div>
				<div className="block block-value">
					<span className="title">Approved USDT amount</span>
					<span className="value">
						<BlockAmount amount={accountData.approvedUSDT} /><span className="dim">USDT</span>
					</span>
				</div>

				<div className="block">
					<div className="btn-group">
						<input type="number" className="form-control amt" style={{ textAlign: 'right' }}
							value={approveAmount} onChange={(e) => setApproveAmount(parseFloat(e.target.value))} />
						<button type="button"
							className={'btn btn-primary'}
							onClick={() => dispatch(doApproveUSDT(approveAmount))} >Approve</button>
					</div>
				</div>

				{PACKAGES.map(pack => <div key={pack.level} className="block">
					<button type="button" className='btn btn-primary main'
						disabled={accountData.userLVL >= pack.level || accountData.approvedUSDT < pack.amount}
						onClick={() => buy(pack.amount)} >{pack.label}
						{accountData.userLVL >= pack.level && <i className='bi bi-check' style={{ "color": "black" }} ></i>}
					</button>
				</div>)}

			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>

		</div>
	)
};


