import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _addMessageError, accountDataUpdate, sun3, transactionConfirmPlease, transactionError, transactionQuery, transactionQueryError, transactionReady, transactionWaitsChain } from "../../app/web3Slice";
import { sale_address, token_address, treasury_address, usdc_address, usdt_address } from "../../config/config";
import { sale_abi } from "../../config/sale_abi";
import { token_abi } from "../../config/token_abi";
import { usdc_abi } from "../../config/usdc_abi";
import { usdt_abi } from "../../config/usdt_abi";
import { treasury_abi } from "../../config/treasury_abi";

export const web3BuySlice = createSlice({
	initialState: {},
	name: 'web3/buy',
	reducers: {


	},
	extraReducers: (builder) => { // ------------------------------------------- EXTRA ---------------------------------
		//builder.addCase(doBuyApprove.rejected, (state, action) => { console.error('doBuyApprove REJECTED!', action.error); });
		//builder.addCase(doBuyBuy.rejected, (state, action) => { console.error('doBuyBuy REJECTED!', action.error); });

		builder.addCase(doBuyBNB.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doApproveUSDT.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doBuyUSDT.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doApproveUSDC.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doBuyUSDC.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doBuyComission.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doComissionWithdraw.rejected, (state, action) => { _addMessageError(state, action); });
	}
});


export const { } = web3BuySlice.actions;
export default web3BuySlice.reducer;

export const PACKAGES = [
	{ amount: 150, level: 1, label: 'EASY - 150 USDT' },
	{ amount: 500, level: 2, label: 'BASIC - 500 USDT' },
	{ amount: 2000, level: 3, label: 'MASTER - 2000 USDT' },
	{ amount: 10000, level: 4, label: 'TOP - 10000 USDT' }
];


// ==================================================== BNB ====================================================
export const doBuyBNB = createAsyncThunk('web3/buy/bnb', async ({amount, accountData}, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		//let amount = BigInt(amountStr * 1e18);

		const saleContract = new web3.eth.Contract(sale_abi, sale_address);
		const tokenContract = new web3.eth.Contract(token_abi, token_address);

		let bnbValue = amount  /  accountData.bnbPriceBNB  * 100 / (100-accountData.bnbWalletFee);
		console.log(bnbValue);
		bnbValue = BigInt(Math.round(bnbValue * 1e18));
		console.log(bnbValue);
		amount = BigInt(Math.round(amount * 1e18));

		// ---- advanced error ----
		/*let gas = await saleContract.methods.buyWithBNB(amount).encodeABI();
		await web3.eth.estimateGas({
			from: sun3.selectedAccount,
			to: sale_address,
			data: gas,
		});*/

		const gasPrice = await web3.eth.getGasPrice();
		await saleContract.methods.buyWithBNB(amount)
			.send({
				from: sun3.selectedAccount,
				value: bnbValue.toString(),
				gasPrice,
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let balanceBNB = await web3.eth.getBalance(sun3.selectedAccount) / 1e18;
					let balanceToken = await tokenContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();
					thunkAPI.dispatch(accountDataUpdate({
						balanceBNB, balanceToken, userLVL
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}




});


// ==================================================== USDT APPROVE ====================================================
export const doApproveUSDT = createAsyncThunk('web3/approve/usdt', async (amount, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		amount = BigInt(amount * 1e18);

		const usdtContract = new web3.eth.Contract(usdt_abi, usdt_address);
		
		const gasPrice = await web3.eth.getGasPrice();
		await usdtContract.methods.approve(sale_address, amount)
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let approvedUSDT = await usdtContract.methods.allowance(sun3.selectedAccount, sale_address).call() / 1e18;
					thunkAPI.dispatch(accountDataUpdate({
						approvedUSDT
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});

// ==================================================== USDT BUY ====================================================
export const doBuyUSDT = createAsyncThunk('web3/buy/usdt', async (amount, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		amount = BigInt(amount * 1e18);

		const usdtContract = new web3.eth.Contract(usdt_abi, usdt_address);
		const saleContract = new web3.eth.Contract(sale_abi, sale_address);
		const tokenContract = new web3.eth.Contract(token_abi, token_address);



		// ---- advanced error ----
		let gas = await saleContract.methods.buyWithUSDT(amount).encodeABI();
		await web3.eth.estimateGas({
			from: sun3.selectedAccount,
			to: sale_address,
			data: gas,
		});



		const gasPrice = await web3.eth.getGasPrice();
		await saleContract.methods.buyWithUSDT(
			amount
		)
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let approvedUSDT = await usdtContract.methods.allowance(sun3.selectedAccount, sale_address).call() / 1e18;
					let balanceUSDT = await usdtContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let balanceToken = await tokenContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();



					thunkAPI.dispatch(accountDataUpdate({
						approvedUSDT, balanceUSDT, balanceToken, userLVL
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});



// ==================================================== USDC APPROVE ====================================================
export const doApproveUSDC = createAsyncThunk('web3/approve/usdc', async (amount, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		amount = BigInt(amount * 1e18);

		const usdcContract = new web3.eth.Contract(usdc_abi, usdc_address);
		const gasPrice = await web3.eth.getGasPrice();
		await usdcContract.methods.approve(
			sale_address,
			amount
		)
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let approvedUSDC = await usdcContract.methods.allowance(sun3.selectedAccount, sale_address).call() / 1e18;
					thunkAPI.dispatch(accountDataUpdate({
						approvedUSDC
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});


// ==================================================== USDC BUY ====================================================
export const doBuyUSDC = createAsyncThunk('web3/buy/usdc', async (amount, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		amount = BigInt(amount * 1e18);

		const usdcContract = new web3.eth.Contract(usdc_abi, usdc_address);
		const saleContract = new web3.eth.Contract(sale_abi, sale_address);
		const tokenContract = new web3.eth.Contract(token_abi, token_address);


		// ---- advanced error ----
		let gas = await saleContract.methods.buyWithUSDC(amount).encodeABI();
		await web3.eth.estimateGas({
			from: sun3.selectedAccount,
			to: sale_address,
			data: gas,
		});

		const gasPrice = await web3.eth.getGasPrice();
		await saleContract.methods.buyWithUSDC(
			amount
		)
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let approvedUSDC = await usdcContract.methods.allowance(sun3.selectedAccount, sale_address).call() / 1e18;
					let balanceUSDC = await usdcContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let balanceToken = await tokenContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();



					thunkAPI.dispatch(accountDataUpdate({
						approvedUSDC, balanceUSDC, balanceToken, userLVL
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});

// ==================================================== COMISSION BUY ====================================================
export const doBuyComission = createAsyncThunk('web3/buy/comission', async (amount, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		amount = BigInt(amount * 1e18);

		const saleContract = new web3.eth.Contract(sale_abi, sale_address);
		const tokenContract = new web3.eth.Contract(token_abi, token_address);


		// ---- advanced error ----
		let gas = await saleContract.methods.buyFromComission(amount).encodeABI();
		await web3.eth.estimateGas({
			from: sun3.selectedAccount,
			to: sale_address,
			data: gas,
		});

		const gasPrice = await web3.eth.getGasPrice();
		await saleContract.methods.buyFromComission(amount)
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let balanceToken = await tokenContract.methods.balanceOf(sun3.selectedAccount).call() / 1e18;
					let comission = await saleContract.methods.getComissionBalance(sun3.selectedAccount).call() / 1e18;
					let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();
					let availableComission = comission;
					if (userLVL == 1) {
						availableComission = comission / 2;
					} else if (userLVL == 2) {
						availableComission = comission / 10 * 6;
					} else if (userLVL == 3) {
						availableComission = comission / 10 * 7;
					}


					thunkAPI.dispatch(accountDataUpdate({
						comission, availableComission, balanceToken, userLVL
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});

// ==================================================== WITHDRAW COMISSION ====================================================
export const doComissionWithdraw = createAsyncThunk('web3/comission/widthdraw', async (_, thunkAPI) => {

	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		//amount = BigInt(amount * 1e18);

		const treasuryContract = new web3.eth.Contract(treasury_abi, treasury_address);
		const saleContract = new web3.eth.Contract(sale_abi, sale_address);


		// ---- advanced error ----
		let gas = await treasuryContract.methods.moneyWithdraw().encodeABI();
		await web3.eth.estimateGas({
			from: sun3.selectedAccount,
			to: treasury_address,
			data: gas,
		});

		const gasPrice = await web3.eth.getGasPrice();
		await treasuryContract.methods.moneyWithdraw()
			.send({
				from: sun3.selectedAccount, gasPrice
			}).once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);

					let comission = await saleContract.methods.getComissionBalance(sun3.selectedAccount).call();
					let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();
					let availableComission = comission;
					if (userLVL == 1){
						availableComission = comission/2;
					}else if (userLVL == 2){
						availableComission = comission/10*6;
					}else if (userLVL == 3){
						availableComission = comission/10*7;
					}		

					thunkAPI.dispatch(accountDataUpdate({
						comission, availableComission, userLVL
					}))

					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});



export const doBuyBuy = createAsyncThunk('web3/buy/buy', async (amount, thunkAPI) => { });
export const doBuyApprove = createAsyncThunk('web3/buy/buy', async (amount, thunkAPI) => { });


