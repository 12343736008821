import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, addToken, doLogin, doLogout, selectAccountData } from './web3Slice';
import { Link } from 'react-router-dom';
import { SmLink } from '../components/elems';
import { SponsorWarning } from '../components/SponsorWarning';




export default function Home() {

	// ------- selectors -----------
	const userData = useSelector(state => state.web3.userData);
	const accountData = useSelector(selectAccountData);

	const dispatch = useDispatch();

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	return (
		<div className="page">
			<h1>COMMUNITY TOKEN</h1>
			<div className="page-main">

				{!accountData.isAuthorized
					? <div className='error' >
						<br /><br /><br /><br /><br />
						Your account is not synchronized with blockchain!<br />
						Wait a minute and refresh your browser please!
						<br /><br /><br /><br /><br />
					</div>
					: <>

						<br />
						<SmLink to="/wallet" icon="wallet">Wallet</SmLink>
						<SmLink to="/buy/bnb" icon="currency-exchange">Buy with BNB</SmLink>
						<SmLink to="/buy/usdt" icon="currency-exchange">Buy with USDT</SmLink>
						<SmLink to="/buy/usdc" icon="currency-exchange">Buy with USDC</SmLink>
						<SmLink to="/buy/comission" icon="currency-exchange">Buy with Comission</SmLink>
						<SmLink to="/comission/withdraw" icon="safe">Withdraw comission</SmLink>
						<SmLink to="/deposit" icon="qr-code">Buy with deposit</SmLink>

					</>
				}
				<hr />

				<div className="other-buttons">
					{!userData?.userId
						&& <button className='btn btn-primary' onClick={() => dispatch(doLogin())} >Login</button>}
					<button className='btn btn-secondary btn-black' onClick={() => dispatch(addToken())}  >Add Token to your wallet</button>
				</div>

				<hr />
				<div className="other-buttons">
					{!!userData?.userId && <button className='btn btn-primary' onClick={() => dispatch(doLogout())}  >Logout</button>}
					{/*<button className='btn btn-light' onClick={() => disconnectIt()}  > Disconnect wallet</button>*/}
				</div>



			</div>
		</div>
	)
};


