import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, dispNum } from '../../common';
import { PACKAGES, doApproveUSDC, doBuyUSDC } from './buySlice';
import { SponsorWarning } from '../../components/SponsorWarning';




export default function BuyUSDC() {

	const accountData = useSelector(selectAccountData);

	const [approveAmount, setApproveAmount] = useState(0);

	const dispatch = useDispatch();

	const buy = (amount) => {
		dispatch(doBuyUSDC(amount))
	}

	return (
		<div className="page">
			<h1>Buy with USDC</h1>
			<div className="page-main">
				<SponsorWarning />
				<div className="block block-value">
					<span className="title">USDC balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDC} /><span className="dim">USDC</span>
					</span>
				</div>
				<div className="block block-value">
					<span className="title">Approved USDC amount</span>
					<span className="value">
						<BlockAmount amount={accountData.approvedUSDC} /><span className="dim">USDC</span>
					</span>
				</div>

				<div className="block">
					<div className="btn-group">
						<input type="number" className="form-control amt" style={{ textAlign: 'right' }}
							value={approveAmount} onChange={(e) => setApproveAmount(parseFloat(e.target.value))} />
						<button type="button"
							className={'btn btn-primary'}
							onClick={() => dispatch(doApproveUSDC(approveAmount))} >Approve</button>
					</div>
				</div>

				{PACKAGES.map(pack => <div key={pack.level} className="block">
					<button type="button" className='btn btn-primary main'
						disabled={accountData.userLVL >= pack.level || accountData.approvedUSDC < pack.amount}
						onClick={() => buy(pack.amount)} >{pack.label}
						{accountData.userLVL >= pack.level && <i className='bi bi-check' style={{"color":"black"}} ></i>}
					</button>
				</div>)}


			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>
		</div>
	)
};


