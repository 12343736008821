import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { sale_abi } from "../config/sale_abi";
import { token_abi } from "../config/token_abi";
import { usdt_abi } from "../config/usdt_abi";
import { usdc_abi } from "../config/usdc_abi";
import { CHAIN, sale_address, token_address, API_BASE, usdt_address, usdc_address } from '../config/config';
import { depositStarted, doDepositStart } from '../features/deposit/depositSlice';


// -------------------- shoud've encapsulate ----------------------------
export const sun3 = {
	Web3Modal: window.Web3Modal.default,
	web3Modal: null, // set in init(),
	WalletConnectProvider: window.WalletConnectProvider.default,
	provider: null, // set in init(), connect()
	selectedAccount: null
}


// -------------------- /shoud've encapsulate ----------------------------

const STATUSES = ['disconnected', 'connecting', 'networksetup', 'query', 'ready', 'error', 'connecterror'];

export const web3Slice = createSlice({
	name: 'web3',
	initialState: {
		status: 'disconnected',
		statusText: null,
		walletAddress: null,

		messages: [],

		userData: {
			userId: null,
			isAdmin: false,
			email: null,
			phone: null
		},

		accountData: {
			isAuthorized: null,
			userLVL: null,
			parent: null,

			balanceBNB: null,
			balanceToken: null,
			balanceUSDT: null,
			balanceUSDC: null,

			approvedUSDT: null,
			approvedUSDC: null,

			comission: null,
			availableComission: null,


		},

		childList: []

	},
	reducers: {
		addMessage: (state, action) => {
			let msg = action.payload;
			if (typeof msg == 'string') {
				msg = {
					message: msg
				};
			}
			MSG_ID++
			msg.id = MSG_ID;
			msg.ts = new Date().getTime();
			state.messages.push(msg);
		},
		addMessageError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			MSG_ID++
			let msg = { message: errorText, variant: 'danger' }
			msg.id = MSG_ID;
			msg.ts = new Date().getTime();
			state.messages.push(msg);
			state.status = 'ready';
		},
		messageCleanup: (state) => {
			let newMsgs = [];
			let ts = new Date().getTime();
			let changed = false;
			for (let msg of state.messages) {
				if (ts - msg.ts < 5000) {
					newMsgs.push(msg);
				} else {
					changed = true;
				}

			}
			if (changed)
				state.messages = newMsgs;
		},
		setStatus: (state, action) => {
			console.log('STATUS', action.payload);
			let status = action.payload;
			let statusText = null;
			if (typeof status == 'object') {
				statusText = status.text;
				status = status.status;
			}
			if (STATUSES.indexOf(status) == -1)
				console.error('INVALID STATUS', action.payload);
			if (status == 'networksetup' && !statusText)
				statusText = 'Confirm the network please!';
			state.status = status;
			state.statusText = statusText
		},
		setError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			state.status = 'error';
			state.statusText = errorText
		},
		setConnectError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			state.status = 'connecterror';
			state.statusText = errorText
		},
		errorClose: (state, action) => {
			if (state == 'connecterror') {
				state.status = 'disconnected';
				state.statusText = null;
			} else {
				state.status = 'ready';
				state.statusText = null;
			}
		},


		walletIdSet: (state, action) => {
			state.accountData.refId = action.payload;
		},
		walletAddressSet: (state, action) => {
			state.walletAddress = action.payload;
		},
		userDataSet: (state, action) => {
			let ud = action.payload || {};
			state.userData = { ...state.userData, ...ud };
			//console.log('::::smnetData', state.userData.smnetData)
		},
		userDataReset: (state, action) => {
			state.userData = {};
			//console.log('::::smnetData', state.userData.smnetData)
		},
		fetchAccountDataSet: (state, action) => {
			if (action.payload === true) {
				//console.log('-- fetch return pending --');
				return;
			}
			state.accountData = action.payload.accountData;
			state.status = 'ready';
			state.statusText = null;
		},
		accountDataUpdate: (state, action) => {
			state.accountData = { ...state.accountData, ...action.payload }
		},

		// --- buy ----
		approveLimitSet: (state, action) => {
			state.accountData.USDTApproveLimit = action.payload;
		},
		buyConfirmed: (state, action) => {
			state.accountData.USDTApproveLimit = action.payload.USDTApproveLimit;
			state.accountData.uBalance = action.payload.uBalance;
			state.accountData.tBalance = action.payload.tBalance;
		},
		setChildList: (state, action) => {
			console.log('setChildList', action.payload)
			state.childList = action.payload;
		}
	},
	extraReducers: (builder) => { // ------------------------------------------- EXTRA ---------------------------------

		builder.addCase(web3init.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(fetchAccountData.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(disconnect.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(connect.rejected, (state, action) => { _addMessageError(state, action); });

		builder.addCase(doLogin.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doCheckLogin.rejected, (state, action) => { _addMessageError(state, action); });

		builder.addCase(addToken.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(loadChildList.rejected, (state, action) => { _addMessageError(state, action); });
	}
})

// =============================  Action creators are generated for each case reducer function =======================
export const {
	addMessage, addMessageError, messageCleanup
	, setStatus, setError, setConnectError, errorClose

	, fetchAccountDataSet, accountDataUpdate
	, walletAddressSet, userDataSet, userDataReset
	, walletIdSet

	, approveLimitSet, buyConfirmed

	, setChildList

} = web3Slice.actions
export default web3Slice.reducer;



// --------------- sector --------------------
export const selectAccountData = state => state.web3.accountData;


export function prepareEx(ex) {
	console.error(ex);

	const x = 'Internal JSON-RPC error.\n';
	if (typeof ex?.message === 'string' && ex.message.startsWith(x)) {
		const jsonStr = ex.message.substring(x.length);
		try {
			const json = JSON.parse(jsonStr);
			if (json && json.message)
				return json.message;
		} catch (ex2) {
			console.error(ex2);
		}
	}
	return ex?.response?.data?.publicMessage || ex?.message || JSON.stringify(ex);
}

// ========================================== MESSAGE ==========================================
// CALL ONLY FORM EXTRA REDUCER REJECT!
let MSG_ID = 0;
export const _addMessageError = (state, action) => {
	web3Slice.caseReducers.addMessage(state.web3 || state, { type: 'web3/addMessage', payload: { message: action.error.message, variant: 'danger' } });
}
export const _setStatus = (state, status, text) => {
	web3Slice.caseReducers.setStatus(state, { type: 'web3/setStatus', payload: { status, text } });
}


// ========================================== FOR THUNKs ==========================================
export function transactionError(thunkAPI, ex) {
	//xxx thunkAPI.dispatch(addMessageError(prepareEx(ex)));
	thunkAPI.dispatch(setError(prepareEx(ex)));

}

export function transactionInitError(thunkAPI, ex) {
	thunkAPI.dispatch(setError(prepareEx(ex)));
}

export function transactionQueryError(thunkAPI, ex) {
	thunkAPI.dispatch(setError(prepareEx(ex)));

	//xxx thunkAPI.dispatch(addMessageError(prepareEx(ex)));
}

export function transactionConfirmPlease(thunkAPI, msg) {
	thunkAPI.dispatch(setStatus({ status: 'query', text: msg || 'Confirm the transaction please' }));
}



export function transactionWaitsChain(thunkAPI, hash) {
	let href = CHAIN.blockExplorerUrls + `tx/${hash}`;
	let text = `Waiting network to confirm <a target='_blank' href='${href}'>transaction</a>`;
	thunkAPI.dispatch(setStatus({ status: 'query', text }));
}


export function transactionQuery(thunkAPI, msg) {
	thunkAPI.dispatch(setStatus({ status: 'query', text: msg || 'Query new value' }));
}

export function transactionReady(thunkAPI, msg) {
	if (msg)
		thunkAPI.dispatch(addMessage(msg));
	thunkAPI.dispatch(setStatus('ready'));
}


// ================================================== CHECK ACCOUNT CHANGE =======================================================

let CheckTimer = 0;
function CheckAccountChangeTimer(thunkApi) {
	const web3 = new Web3(sun3.provider);
	clearInterval(CheckTimer);
	CheckTimer = setInterval(async () => {
		const oldAccount = sun3.selectedAccount;
		const accounts = await web3.eth.getAccounts();
		const account = Array.isArray(accounts) && accounts.length>0 ? accounts[0] : '';
		//console.log('check', oldAccount, account);
		if (account && oldAccount && account !== oldAccount) {
			try {
				await thunkApi.dispatch(doLogout());
			} catch (ex) {
				console.error(ex);
			}
			window.localStorage.clear();
			window.location.href = window.location.href;
		}
	}, 5 * 1000)

};

// ================================================== INIT =======================================================
export const web3init = createAsyncThunk('web3/init', async (_, thunkAPI) => {
	if (window.location.protocol !== 'https:') {
		console.error("Not HTTPS!"); //return;TODO!!!!
	}

	const providerOptions = {
		walletconnect: {
			package: sun3.WalletConnectProvider,
			options: {
				rpc: {
					[parseInt(CHAIN.chainId)]: CHAIN.rpcUrls[0]
				}
				///rpc: {97: "https://data-seed-prebsc-1-s1.binance.org:8545/",},
			}
		},
		binancechainwallet: {
			package: true
		}
	};

	sun3.web3Modal = new sun3.Web3Modal({
		cacheProvider: false, // optional
		providerOptions, // required
		disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});


	if (localStorage.getItem('isWalletConnected') !== 'true')
		return;

	try {
		if (window.ethereum) {
			sun3.provider = window.ethereum;
			console.log('PROVIDER.FROM.INIT (window.ethereum)', sun3.provider);
			const web3 = new Web3(sun3.provider);
			const accounts = await web3.eth.getAccounts();
			if (!accounts || !Array.isArray(accounts) || accounts.length === 0)
				sun3.provider = null;

		}

		if (!window.ethereum || !sun3.provider) {
			sun3.provider = await sun3.web3Modal.connect();
			console.log('PROVIDER.FROM.INIT (web3Modal.connect())', sun3.provider);
		}
		thunkAPI.dispatch(fetchAccountData())
	} catch (ex) {
		thunkAPI.dispatch(disconnect());
		transactionInitError(thunkAPI, ex || 'Metamask popup aleready open!');
	}
	CheckAccountChangeTimer(thunkAPI);

});


// ================================================== CHECK LOGIN =======================================================
export const doCheckLogin = createAsyncThunk('web3/startup', async (_, thunkAPI) => {
	try {
		let state = thunkAPI.getState();
		let alreadyUserData = state.web3.userData;
		if (alreadyUserData && alreadyUserData.userId) // már beloginoltunk csak a fetchAccount a végén mindig meghív
			return;

		let sess_WalletAddress = window.localStorage.getItem('walletAddress');
		let sess_nonce = window.localStorage.getItem('nonce');
		if (!sess_WalletAddress || !sess_nonce)
			return;


		const web3 = new Web3(sun3.provider);
		const accounts = await web3.eth.getAccounts();
		const walletAddress = sun3.selectedAccount = accounts[0];
		if (!walletAddress) {
			return;
		}
		thunkAPI.dispatch(walletAddressSet(walletAddress));
		let body = { walletAddress };

		if (sess_WalletAddress == walletAddress) {
			body.nonce = sess_nonce;
		} else {
			//window.localStorage.removeItem('walletAddress');
			//window.localStorage.removeItem('nonce');
			window.localStorage.clear();
			window.location.href = window.location.href;
			return;
		}
		transactionQuery(thunkAPI, "Checking server...");

		if (window.localStorage.getItem('parentReferral'))
			body.parentReferral = window.localStorage.getItem('parentReferral');

		const resp = await axios.post(API_BASE + '/startup', body, { withCredentials: true });
		if (resp.data.userId) { // ---- already logged in ----
			await thunkAPI.dispatch(userDataSet(resp.data));
			await thunkAPI.dispatch(fetchAccountData());

			if (resp.data.tran) {
				await thunkAPI.dispatch(depositStarted(resp.data.tran))

			}
		}
		transactionReady(thunkAPI);
	} catch (ex) {
		transactionInitError(thunkAPI, ex);
	}
});
// ================================================== LOGIN =======================================================
export const doLogin = createAsyncThunk('web3/login', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Checking server...");
		const web3 = new Web3(sun3.provider);

		const accounts = await web3.eth.getAccounts();
		const walletAddress = sun3.selectedAccount = accounts[0];
		if (!walletAddress) {
			thunkAPI.dispatch(connect());
			return;
		}
		thunkAPI.dispatch(walletAddressSet(walletAddress));

		let sess_WalletAddress = window.localStorage.getItem('walletAddress');
		let sess_nonce = window.localStorage.getItem('nonce');
		let body = { walletAddress };
		if (sess_WalletAddress == walletAddress) {
			body.nonce = sess_nonce;
		} else {
			window.localStorage.removeItem('walletAddress');
			window.localStorage.removeItem('nonce');
		}
		if (window.localStorage.getItem('parentReferral'))
			body.parentReferral = window.localStorage.getItem('parentReferral');

		const resp = await axios.post(API_BASE + '/startup', body, { withCredentials: true });
		if (resp.data.userId) { // ---- already logged in ----
			thunkAPI.dispatch(userDataSet(resp.data));
			thunkAPI.dispatch(fetchAccountData());
			return;
		}

		const nonce = resp.data.nonce;
		if (!nonce || !nonce.length || nonce.length < 10)
			throw "Something wrong with nonce.";
		transactionQuery(thunkAPI, "Sign your login code please!");
		let nonceSigned = await web3.eth.personal.sign(web3.utils.fromUtf8(nonce), walletAddress);
		//console.log('nonceSigned', nonceSigned);
		transactionQuery(thunkAPI, "Logging in...");
		const loginResp = await axios.post(API_BASE + '/login', {
			walletAddress, nonce, nonceSigned
			, parentReferral: window.localStorage.getItem('parentReferral')
		}, { withCredentials: true });
		//console.log('resp', loginResp.data);

		window.localStorage.setItem('walletAddress', walletAddress);
		window.localStorage.setItem('nonce', nonce);

		thunkAPI.dispatch(userDataSet(loginResp.data));
		transactionReady(thunkAPI);
		//thunkAPI.dispatch(fetchAccountData());

	} catch (ex) {
		transactionInitError(thunkAPI, ex);
	}
});

// ================================================== LOGOUT =======================================================
export const doLogout = createAsyncThunk('web3/logout', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Logging out...");
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');
		if (walletAddress && nonce) {
			await axios.post(API_BASE + '/logout', { walletAddress, nonce }, { withCredentials: true });
			window.localStorage.removeItem('walletAddress');
			window.localStorage.removeItem('nonce');
		}
		thunkAPI.dispatch(userDataReset());
		transactionReady(thunkAPI);

	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

// ==================================================== CONNECT ====================================================
export const connect = createAsyncThunk('web3/connect', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('connecting'));
	//if (localStorage.getItem('isWalletConnected') !== 'true') {
	console.log('0) before connect', sun3.provider);

	sun3.provider = await sun3.web3Modal.connect();

	console.log('PROVIDER.FROM.CONNECT', sun3.provider);

	localStorage.setItem('isWalletConnected', true);
	const web3 = new Web3(sun3.provider);

	console.log('2) web3', web3);
	sun3.provider.enable();
	console.log('3) enabled');

	// Get connected chain id from Ethereum node
	const chainId = await web3.eth.getChainId();
	console.log('4) chainID', chainId);

	// Load chain information over an HTTP API
	const chainData = evmChains.getChain(chainId);
	console.log('5) chain', chainId, chainData, chainData.name);


	if (chainId != CHAIN.chainId) {
		console.log("CALL networkChange");
		thunkAPI.dispatch(changeNetwork());
	} else {
		// xxx await thunkAPI.dispatch(doLogin());
		console.log("CALL fetchAccountData");
		await thunkAPI.dispatch(fetchAccountData());
	}

	
});

// ==================================================== DISCONNECT ====================================================
export const disconnect = createAsyncThunk('web3/disconnect', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('disconnected'));
	localStorage.setItem('isWalletConnected', false);
	console.log("Killing the wallet connection", sun3.provider);

	// TODO: Which providers have close method?
	if (sun3.provider?.close) {
		await sun3.provider.close();
		console.log('privider close returned');
		// If the cached provider is not cleared,
		// WalletConnect will default to the existing session
		// and does not allow to re-scan the QR code with a new wallet.
		// Depending on your use case you may want or want not his behavir.
		await sun3.web3Modal.clearCachedProvider();
		sun3.provider = null;
		console.log('clear cache returned');
	}
	sun3.selectedAccount = null;
});


// ==================================================== CHANGE  NETWORK ====================================================
const changeNetwork = createAsyncThunk('web3/changeNetwork', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('networksetup'));
	console.log("BEFORE CHANGENETWORK");
	await ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [{ chainId: CHAIN.chainId }]
	})
		.then(async (a, b, c) => {
			await thunkAPI.dispatch(fetchAccountData());
		})
		.catch((err) => {
			console.error('change catch', err.code);
			if (err?.code == 4902) {
				//addFuseNetwork(chainId);
				thunkAPI.dispatch(addNetwork());
			} else if (err?.code == -32002) {
				thunkAPI.dispatch(setStatus('networksetup'))
			} else {
				//setError({ status: null, code: err.code }); //??? TODO
				thunkAPI.dispatch(setConnectError(err));
			}
		});
});


// ==================================================== ADD NETWORK ====================================================
export const addNetwork = createAsyncThunk('web3/addNetwork', async () => {
	ethereum.request({
		method: 'wallet_addEthereumChain',
		params: [CHAIN]
	})
		.once(async () => {
			//xxx await thunkAPI.dispatch(doLogin());
			await thunkAPI.dispatch(fetchAccountData());
		})
		.catch((error) => {
			console.log(error) //TODO
		});
});













// ==================================================== FETCH ACCOUNT DATA ====================================================

export const fetchAccountData = createAsyncThunk('web3/fetchAccountData', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Fetching account data...");
		const web3 = new Web3(sun3.provider);


		const chainId = await web3.eth.getChainId();

		if (chainId != CHAIN.chainId) {
			thunkAPI.dispatch(changeNetwork());
			return true; // pending
		}

		// Get list of accounts of the connected wallet
		const accounts = await web3.eth.getAccounts();

		// MetaMask does not give you all accounts, only the selected account
		sun3.selectedAccount = accounts[0];

		// ----------------------------- BNB ----------------------------
		let balanceBNB = await web3.eth.getBalance(sun3.selectedAccount);
		balanceBNB = (balanceBNB / 1e18).toFixed(4);


		// ----------------------------- Token ----------------------------
		const tokenContract = new web3.eth.Contract(token_abi, token_address);
		let balanceToken = await tokenContract.methods.balanceOf(sun3.selectedAccount).call();
		balanceToken = (balanceToken / 1e18).toFixed(4);


		// ----------------------------- USDT ----------------------------
		const usdtContract = new web3.eth.Contract(usdt_abi, usdt_address);
		let balanceUSDT = await usdtContract.methods.balanceOf(sun3.selectedAccount).call();
		balanceUSDT = (balanceUSDT / 1e18).toFixed(4);

		let approvedUSDT = await usdtContract.methods.allowance(sun3.selectedAccount, sale_address).call();
		approvedUSDT = (approvedUSDT / 1e18).toFixed(4);


		// ----------------------------- USDC ----------------------------
		const usdcContract = new web3.eth.Contract(usdc_abi, usdc_address);
		let balanceUSDC = await usdcContract.methods.balanceOf(sun3.selectedAccount).call();
		balanceUSDC = (balanceUSDC / 1e18).toFixed(4);

		let approvedUSDC = await usdcContract.methods.allowance(sun3.selectedAccount, sale_address).call();
		approvedUSDC = (approvedUSDC / 1e18).toFixed(4);


		// ----------------------------- Sale ----------------------------
		const saleContract = new web3.eth.Contract(sale_abi, sale_address);
		let isAuthorized = await saleContract.methods.getUser(sun3.selectedAccount).call();
		console.log('------> authorized <-------', isAuthorized, typeof isAuthorized);
		let parent = await saleContract.methods.getParent(sun3.selectedAccount).call();
		let userLVL = await saleContract.methods.getUserLVL(sun3.selectedAccount).call();

		let comission = await saleContract.methods.getComissionBalance(sun3.selectedAccount).call();
		comission = (comission / 1e18).toFixed(4);
		//comission = 1500; comission = comission.toFixed(4);		
		let availableComission = comission;
		if (userLVL == 1) {
			availableComission = comission / 2;
		} else if (userLVL == 2) {
			availableComission = comission / 10 * 6;
		} else if (userLVL == 3) {
			availableComission = comission / 10 * 7;
		}


		let bnbWalletFee = Number(await saleContract.methods.bnbWalletFee().call());
		let depositStableFee = Number(await saleContract.methods.depositStableFee().call());
		let depositCoinFee = Number(await saleContract.methods.depositCoinFee().call())

		let bnbPriceBNB = Number(await saleContract.methods.getOneBNBPrice().call()) / 1e18;
		let bnbPriceETH = Number(await saleContract.methods.getOneETHPrice().call()) / 1e18;



		// ------------------------------------ save to store --------------------------------------------
		
		thunkAPI.dispatch(walletAddressSet(sun3.selectedAccount));
		let result = {
			accountData: {
				isAuthorized,
				userLVL,
				parent,

				balanceBNB,
				balanceToken,

				balanceUSDT,
				approvedUSDT,

				balanceUSDC,
				approvedUSDC,

				comission,
				availableComission,

				bnbWalletFee, depositStableFee, depositCoinFee,
				bnbPriceBNB, bnbPriceETH
			},
		};
		thunkAPI.dispatch(fetchAccountDataSet(result));
		transactionReady(thunkAPI);
		thunkAPI.dispatch(doCheckLogin()); // TODO: kellez?
	}
	catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
	CheckAccountChangeTimer(thunkAPI);

}); // fetchAccountData


// ==================================================== ADD TOKEN ====================================================
export const addToken = createAsyncThunk('web3/addToken', async () => {
	const wasAdded = await ethereum.request({
		method: 'wallet_watchAsset',
		params: {
			type: 'ERC20', // Initially only supports ERC20, but eventually more!
			options: {
				address: token_address, // The address that the token is at.
				symbol: 'SToken', // A ticker symbol or shorthand, up to 5 chars.
				decimals: 18 //, The number of decimals in the token
				//image: tokenImage, // A string url of the token logo
			},
		},
	});

	if (wasAdded) {
		console.log('Thanks for your interest!');
	} else {
		console.log('Your loss!');
	}
});



export const loadChildList = createAsyncThunk('web3/loadchildlist', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Fetching sponsored accounts...");
		const web3 = new Web3(sun3.provider);
		await window.ethereum.enable();

		const sale = new web3.eth.Contract(sale_abi, sale_address);
		let childAddressArr = await sale.methods.getChildrenElements(sun3.selectedAccount).call();
		//let childAddressArr = await sale.methods.getChildsElements("0x4F2C5acb8Bd74089751Dc7f15E4Ef8c826ac153d").call();
		console.log('childAddressArr', childAddressArr);
		thunkAPI.dispatch(setChildList(childAddressArr));
		transactionReady(thunkAPI);
	}
	catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});











