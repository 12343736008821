export function dispNum(num, d) {
	if (isNaN(parseFloat(num)))
		return '-';
	let params = {
		minimumFractionDigits: (typeof d == 'number' ? d : 2),
		maximumFractionDigits: (typeof d == 'number' ? d : 4)
	};
	return parseFloat(num)?.toLocaleString(undefined, params);
}

export function BlockAmount({ amount, frac }) {
	return (
		<span className="amount">{dispNum(amount, frac)}</span>
	)
}

export function BlockDim({ dim }) {
	return (
		<span className="dim">{dim}</span>
	)
}

export function dispAddress(a) {
	if (!a || typeof a != 'string')
		return '[n/a]';
	if (!a.startsWith('0x'))
		a = '0x' + a;
	return a.substr(0, 9) + ' ... ' + a.substr(-8);
}

export function dispTime(f) {
	if (typeof f == 'string') {
		var d = Date.parse(f);
		if (!isNaN(d)) {
			f = new Date(f);
		}
	}

	if (f instanceof Date) {
		var mm = f.getMonth() + 1; // getMonth() is zero-based
		var dd = f.getDate();

		var hh = f.getHours();
		var mi = f.getMinutes();
		var ss = f.getSeconds();

		return [
			(''+f.getFullYear()).substring(2), '-',
			(mm > 9 ? '' : '0') + mm, '-',
			(dd > 9 ? '' : '0') + dd, ' ',
			(hh > 9 ? '' : '0') + hh, ':',
			(mi > 9 ? '' : '0') + mi
			//, ':',	(ss > 9 ? '' : '0') + ss
		].join('');
	} else {
		return f;
	}
}



export function normalizeInputNumber(a) {
	if (typeof a === 'number')
		return a;
	else if (typeof a === 'string')
		return parseFloat(a.replace(',', '.'));
	else return a;
}


// ======================================================= CSV PARSER =================================================
export function CSVToArray(strData, strDelimiter) {
	// Check to see if the delimiter is defined. If not,
	// then default to comma.
	strDelimiter = (strDelimiter || ",");

	// Create a regular expression to parse the CSV values.
	var objPattern = new RegExp(
		(
			// Delimiters.
			"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

			// Quoted fields.
			"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

			// Standard fields.
			"([^\"\\" + strDelimiter + "\\r\\n]*))"
		),
		"gi"
	);


	// Create an array to hold our data. Give the array
	// a default empty first row.
	var arrData = [[]];

	// Create an array to hold our individual pattern
	// matching groups.
	var arrMatches = null;


	// Keep looping over the regular expression matches
	// until we can no longer find a match.
	while (arrMatches = objPattern.exec(strData)) {

		// Get the delimiter that was found.
		var strMatchedDelimiter = arrMatches[1];

		// Check to see if the given delimiter has a length
		// (is not the start of string) and if it matches
		// field delimiter. If id does not, then we know
		// that this delimiter is a row delimiter.
		if (
			strMatchedDelimiter.length &&
			strMatchedDelimiter !== strDelimiter
		) {

			// Since we have reached a new row of data,
			// add an empty row to our data array.
			arrData.push([]);

		}

		var strMatchedValue;

		// Now that we have our delimiter out of the way,
		// let's check to see which kind of value we
		// captured (quoted or unquoted).
		if (arrMatches[2]) {

			// We found a quoted value. When we capture
			// this value, unescape any double quotes.
			strMatchedValue = arrMatches[2].replace(
				new RegExp("\"\"", "g"),
				"\""
			);

		} else {

			// We found a non-quoted value.
			strMatchedValue = arrMatches[3];

		}


		// Now that we have our value string, let's add
		// it to the data array.
		arrData[arrData.length - 1].push(strMatchedValue);
	}

	// Return the parsed data.
	return (arrData);
}