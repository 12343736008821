import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountData } from '../../app/web3Slice';
import { Link } from 'react-router-dom';
import { BlockAmount, dispNum } from '../../common';
import { PACKAGES, doBuyApprove, doBuyBNB, doBuyBuy } from './buySlice';
import { SponsorWarning } from '../../components/SponsorWarning';




export default function BuyBNB() {

	const accountData = useSelector(selectAccountData);

	const dispatch = useDispatch();


	const buy = (amount) => {
		dispatch(doBuyBNB({amount, accountData}))
	}

	return (
		<div className="page">
			<h1>Buy with BNB</h1>
			<div className="page-main">
				<SponsorWarning />
				<div className="block block-value">
					<span className="title">BNB Balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceBNB} /><span className="dim">BNB</span>
					</span>
				</div>

				{PACKAGES.map(pack => <div key={pack.level} className="block">
					<button type="button" className='btn btn-primary main'
						disabled={accountData.userLVL >= pack.level}
						onClick={() => buy(pack.amount)} >{pack.label}
						{accountData.userLVL >= pack.level && <i className='bi bi-check' style={{"color":"black"}} ></i>}
					</button>
				</div>)}


			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>
		</div>
	)
};


