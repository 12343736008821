import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, dispNum, normalizeInputNumber } from '../../common';
import { PACKAGES, doBuyComission, doComissionWithdraw } from './buySlice';




export default function ComissionWithdrawal() {

	const accountData = useSelector(selectAccountData);

	//const [amount, setAmount] = useState(0);

	const dispatch = useDispatch();

	const withdraw = () => {
		dispatch(doComissionWithdraw());
	}

	return (<div className="page">
		<h1>Comission withdrawal</h1>
		<div className="page-main">
			<div className="block block-value">
				<span className="title">Comission</span>
				<span className="value">
					<BlockAmount amount={accountData.comission} /><span className="dim">USDT</span>
				</span>
			</div>

			<div className="block block-value">
				<span className="title">Available for withdrawal</span>
				<span className="value">
					<BlockAmount amount={accountData.availableComission} /><span className="dim">USDT</span>
				</span>
			</div>

			{/*<div className="block block-value">
				<span className="title">Amount to withdraw</span>
				<span className="value">
					<input className="flat amount" value={amount} onChange={(e) => setAmount(normalizeInputNumber(e.currentTarget.value))} /><span className="dim">USD</span>
				</span>
</div>*/}

			<div className="block">
				<button type="button" className='btn btn-primary main'
					disabled={ accountData.availableComission <= 0}
					onClick={withdraw} ><strong>Withdraw</strong>
				</button>
			</div>


		</div>
		<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>
	</div>)

}